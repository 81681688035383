@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  background-color: #131313;
}

.font-family {
  font-family: 'Poppins', sans-serif;
}

.green-grade {
  background: radial-gradient(64.33% 64.33% at 71.16% 35.69%, #dcfdd0 0.89%, #adf194 17.23%, #a8f18d 42.04%, #92e772 55.12%, #7be953 71.54%, #58c92f 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.small-text-grade {
  background: radial-gradient(64.33% 60.33% at 60.16% 35.69%, #dcfdd0 0.89%, #ddf7d3 17.23%, #a8f18d 42.04%, #92e772 55.12%, #c2f2b0 71.54%, #63ca3e 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card {
  border-radius: 20px;
  background: linear-gradient(153deg, rgba(255, 255, 255, 0.00) -341.94%, #121d10 95.11%);
  box-shadow: 0px 20px 100px -10px rgba(66, 91, 66, 0.1);
}

.button-grade{
  border-radius: 10px;
background: linear-gradient(158deg, #dcfdd0 -43.27%, #adf194 -21.24%, #a8f18d 12.19%, #92e772 29.82%, #7be953 51.94%, #58c92f 90.29%);
}

.relative {
  position: relative;
  z-index: 10000000000000000000000;
}

.glow-round {
  position: absolute;
  background-color: #4fff1576;
  width: 200px;
  height: 150px;
  filter: blur(120px);
  top: -0px;
  left: 30px;
  /* z-index: 100000; */
}
.glow-round-right {
  position: absolute;
  background-color: #4fff1576;
  width: 200px;
  height: 150px;
  filter: blur(120px);
  right: 2px;
  bottom: 10px;
  /* top: -0px; */
  /* left: 0px; */
  /* z-index: 100000; */
}

.glow-back {
  position: absolute;
  background-color: #5d605e;
  width: 500px;
  height: 50px;
  filter: blur(120px);
  top: -50px;
  left: 500px;
  z-index: 100000;
}

.index{
  position: absolute;
  z-index: 900000000000000000000000000000000000000000;
  top: 50%;
  left: 50%;
  transform: translate(-50%,50%);
  top: 20%;
}

/* .badges{
  clip-path: polygon(50% 0%, 100% 30%, 100% 70%, 50% 100%, 0% 70%, 0% 30%);
  width: 9rem;
  height: 10rem;
  
} */


::-webkit-scrollbar{
  display: none;
}